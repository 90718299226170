<template >
  <!-- v-if="$vs.rtl" -->
  <div class="w-full">
    <b-row>
      <b-col cols="12" md="3">
        <b-card
          style="'height:300px"
          :img-src="
            require('@/assets/images/patientHome/UpdateMedicalHistory.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("UpdateMedicalHistoryHome") }} 
          </p>
          <b-button
            @click="UpdateMedicalHistory()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("UpdateMedicalHistory") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/YourMedicalReports.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("YourMedicalReportsHome") }}
          </p>
          <b-button
            @click="$router.push({ name: 'PatientMedicalReport' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("YourMedicalReports") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="3">
        <b-card
          :img-src="require('@/assets/images/patientHome/PendingDocument.png')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("PendingDocumentHome") }}
          </p>
          <b-button
            @click="getPendingDocuments()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("PendingDocument") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/PatientPaymentPending.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("PendingPaymentsHome") }}
          </p>
          <b-button
            @click="$router.push({ name: 'PatientPaymentPending' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("PendingPayments") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/SurgeriesTreatments.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("SurgeriesTreatmentsHome") }}
          </p>
          <b-button
            @click="getSurgeriesTreatments()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("SurgeriesTreatments") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="require('@/assets/images/patientHome/Packages.png')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("PackagesHome") }}
          </p>
          <b-button
            @click="$router.push({ name: 'Packages' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("Packages") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/YourOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("YourOnlineConsultationsHome") }}
          </p>
          <b-button
            @click="OpenOnlineConsultations()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("YourOnlineConsultations") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/RequestOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("RequestOnlineConsultationsHome") }}
          </p>
          <b-button
            @click="goToConsultationRequest()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("RequestOnlineConsultations") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="3"> </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/PrintYourPrescription.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("PrintYourPrescriptionHome") }}
          </p>
          <b-button
            @click="$router.push({ name: 'ReservationSessionPrescriptions' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("PrintYourPrescription") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/RepeatMedicalOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("RepeatMedicalOnlineConsultationsHome") }}
          </p>
          <b-button
            @click="$router.push({ name: 'PatientRepeatMedical' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("RepeatMedicalOnlineConsultations") }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//mkl your medical report
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";

import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
} from "bootstrap-vue";
export default {
  components: {
    BCardBody,
    BCardHeader,
    BCardFooter,
    BCol,
    BRow,
    BButton,
    BCard,
    BCardText,
    BImg,
    BLink,
  },
  directives: {
  },
  data() {
    return {
      showPayLaterPop: false,
      updatedPatientSession: {},
      sessionModel: {},
      showSetSession: false,
      showSetPayment: false,
      ShowPatientNote: false,
      showEditSessionDialog: false,
      CancelSessionModel: {},
      EditSessionModel: {},
      doctorModel: {},
      showCancelDialog: false,
      showYourMedicalReportfield: false,
      showReservedPackages: false,
      patientDoc: {},
      ShowJoinPopup: false,
      sessionModel: {},
      documentModel: {},
      showReservationSession: false,
      ShowPatientMedicalReport: false,
      ShowInComingSurgeries: false,
      ShowPatientRepeatMedical: false,
      showReservationSessionPrescriptions: false,
      showPendingMedicalReport: false,
      ShowPatientUploadDocument: false,
      ShowOldSurgeries: false,
      ShowUploadDocument: false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/Patient/Group1.png")})`,
        backgroundSize: "100% 200px",

        backgroundRepeat: "no-repeat",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/Patient/Group2.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG3: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundThird.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG4: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundFourth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG5: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundFifth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG6: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundSixth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
    };
  },

  methods: {
    OpenOnlineConsultations() {
      this.$router.push({ name: "PatientReservationSession" });
    },

    SetNewSession(sessionModel) {
      this.showSetSession = true;
      this.showEditSessionDialog = false;
      this.sessionModel = sessionModel;
    },
    closeSessionPop(updatedPatientSession) {
      debugger;
      this.updatedPatientSession = updatedPatientSession;
      this.showSetSession = false;
      debugger;
      if (this.updatedPatientSession.ID > 0) this.ShowPatientNote = true;
    },

    ClosePayLaterSeccussPopup() {
      this.showPayLaterPop = false;

      this.$router.push({
        name: "BookingSummary",
        params: { Flag: 1, ID: this.updatedPatientSession.ID },
      });
    },
    closeSetPaymentPop(ispaymentNow) {
      this.showSetPayment = false;

      if (!ispaymentNow) {
        debugger;
        //ispaymentNow
        this.showPayLaterPop = true;
      } else {
        this.showSetPayment = true;
        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 1, ID: this.updatedPatientSession.ID },
        });
      }
    },

    ViewEditSessionDialog(doctorID) {
      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", doctorID).then((res) => {
        this.doctorModel = res.data.Data;
        this.showEditSessionDialog = true;

        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },

    joinMeeting(url) {
      debugger;
      this.showReservationSession = false;
      this.sessionModel.JoinUrl = url;
      this.ShowJoinPopup = true;
    },
    showYourMedicalReport() {
      this.patientDoc.ID = this.$store.state.AppActiveUser.Patient.ID;
      debugger;
      this.showYourMedicalReportfield = true;
    },
    openMedicalDocument() {
      this.ShowPatientMedicalReport = true;
      this.ShowPatientUploadDocument = false;
    },
    OpenMedicalUploadDocument(data) {
      debugger;
      this.ShowPatientMedicalReport = false;
      this.ShowPatientUploadDocument = true;
      this.documentModel = data;
    },
    OpenUploadDocument(data) {
      this.ShowUploadDocument = true;
      this.showPendingMedicalReport = false;
      this.documentModel = data;
    },
    closeInComingSurgeriesPopup() {
      debugger;
      this.ShowInComingSurgeries = false;
      this.ShowOldSurgeries = true;
    },

    UpdateMedicalHistory() {
      var id = this.$store.state.AppActiveUser.Patient.ID;
      this.$router.push({ name: "PatientMedicalHistory", params: { ID: id } });
    },
    goToConsultationRequest() {
      this.$router.push({ name: "AppointmentSearch" });
    },
    getSurgeriesTreatments() {
      //  this.ShowInComingSurgeries = true;
      this.$router.push({ name: "PatientSurgeries" });
    },
    getPendingDocuments() {
      this.$router.push({ name: "pendingMedicalReport" });
    },
    SearchPatientReservationSessions() {
      this.showReservationSession = true;
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
<style scoped>
.h5profilePatient {
  font-weight: bold;
}
.backgroundShadow {
  background: #eeee;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  border-radius: 40px;

  height: 90%;
}

.CardImage {
  background-repeat: no-repeat;
}

.headStyle {
  font-size: 17px;
  padding-right: 100px;
}
</style>

>


// <style lang="scss">
//  @import '@/assets/scss/vuexy/_variables.scss';
//   .fontcolor{
//     color:$warning
//   }
//
</style>
